<template>
    <div class="font-poppin">
        <div class="filter-header">
            <div class="fs-14 font-weight-bold ml-4">Product Type Filter (<span style="color:#ffba00 !important;">{{totalFilters}}</span>)</div>
            <div class="pointer">
                <!-- <span @click="closeFilters()">
                    <img class="filter-Images" src="https://cdn.digicollect.com/cdn/scmnew/images/scm/ArrowBgBlue.svg" alt="arrow">
                </span> -->
                <span @click="resetFilter()">
                    <img class="filter-Images" src="https://cdn.digicollect.com/cdn/scmnew/images/scm/ResetBgBlue.svg" alt="reset">
                </span>
                <span @click="closeFilters()">
                    <img class="filter-Images" src="https://cdn.digicollect.com/cdn/scmnew/images/scm/CloseBgBlue.svg" alt="close">
                </span>
            </div>
        </div>
        <div class="">
            <div class="filter-search pt-3 px-3">
                <div class="d-flex align-items-center position-relative my-1">
                    <span class="svg-icon svg-icon-1 position-absolute ms-4">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                            <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                        </svg>
                    </span>
                    <input type="text" @input="onSearchProductTypes" class="search-control form-control-solid w-400px ps-15 pr-8" v-model="product_type_search_key" id="search_product"  placeholder="Search Product Types" />
                </div>
            </div>
            <div v-if="product_type_data.length < 1 && product_type_loading" class="py-3">
                <div class="digi-table-loader d-flex justify-content-center" style="margin: 0 auto">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div v-else-if="product_type_data.length < 1 && !product_type_loading" class="text-center py-3">
                <span>Product Types Not Found</span>
            </div>
            <div v-else class="d-flex align-items-center flex-wrap product-type pb-10 px-2" style="max-height: 250px; overflow: auto;">
                <div v-for="(product_type, p_type_index) in product_type_data" :key="p_type_index+'p_type_index'" class="form-check-label product-type-option p-2 mt-2 flex-column position-relative" :class="{'product-type-option-selected':product_type.selected}">
                    <div>
                        <avatar v-if="product_type.image_url == '' || product_type.image_url == null || !product_type.hasOwnProperty('image_url')"
                            :username="product_type.name.trim()"
                            :src="''"
                            :size="100"
                            :rounded="true" :class="{'active':product_type.selected}"
                            style="width: 50px !important; height: 50px !important; margin-left: auto !important; margin-right: auto !important;"
                            color="#000"
                        ></avatar>
                        <div v-else class="product-type-option-image">
                            <img :src="product_type.image_url" alt="" width="100%" height="100%">
                        </div>
                    </div>
                    <input type="checkbox"
                        class="form-check-input position-absolute pointer" style="left: 10px;top: 8px;width: 100%;height: 90%;" @change="selectProductType($event, product_type,p_type_index)"
                        v-model="product_type.selected" name="modifier_name" />
                    <span class="radio-icon d-none"></span>
                    <div style="margin:auto 0; padding-left: 10px;overflow: hidden;text-overflow: ellipsis;">
                        <span class="fs-15">{{product_type.name}}</span>
                    </div>
                </div>
            </div>
            <div v-if="selected_product_type.length" class="pt-2 pb-3 mx-2" style="border-top: 1px solid #d7dde2">
                <button class="btn btn-secondary text-capitalize" @click="applyFilter" style="border-radius: 8px !important;">Apply Filter</button>
            </div>
        </div>
    </div>
</template>
<script>
import orders from './mixins/orders.js'
import { EventBus } from './eventBus/nav-bar-event'
import Avatar from 'vue-avatar'
import global from './global.js'
export default {
    mixins:[orders],
    props:['new_selected_category_id', 'courseSelected' ,'order_id' ,'searchdata' ,'new_selected_sub_category_id'],
    data(){
        return{
            allOrderTypes:[],
            order_type:[],
            temp_delivery_companies:[],
            temp_online_companies:[],
            delivery_companies:[],
            online_companies:[],
            payment_status:'',
            delivery_company_list:[],
            pay_json:['PAID','UNPAID'],
            paykey:0,
            temp_categories: [],
            categories: [],
            category_list: [],
            categories_search_key : '',
            is_loading_category: false,
            categories_count: 0,
            scrollable: false,
            load_order_type_list: false,
            product_type_search_key: '',
            product_type_data: [],
            product_type_loading: false,
            selected_product_type: []
        }
    },
    components: {
        Avatar
    },
    methods:{
        closeFilters(){
            this.hide_filters = false
            this.$emit('hideFilters')
            this.product_type_search_key = ''
        },
        resetFilter(){
            if(this.totalFilters > 0){
                this.selected_product_type = []
                this.product_type_data.forEach((el,index) => {
                    el.selected = false
                })
                this.product_type_search_key = ''
                //this.applyFilter();
            }
        },
        onSearchProductTypes() {
            this.loadProductTypeFilterData();
        },
        async loadProductTypeFilterData() {
            this.product_type_loading = true
            try {
                let params = {
                    category_id: this.category_id,
                    course: this.courseSelected,
                    order_id: this.order_id,
                    searchdata: this.product_type_search_key,
                    sub_category_id: this.sub_category_id,
                    get_product_types: true
                }
                let response = await this.getNewProductListing(params)
                this.product_type_data = response.response.items
                this.product_type_data.forEach((item, index) => {
                    item['selected'] = false
                })
                if(this.selected_product_type.length) {
                    this.selected_product_type.forEach((selected_p_type)=>{
                        this.product_type_data.forEach((item, index) => {
                            if(selected_p_type._id === item._id) {
                                item['selected'] = true
                            }
                        })
                    })
                }
                console.log(this.product_type_data)
                this.product_type_loading = false
            }
            catch(e) {
                console.log(e)
                this.product_type_loading = false
            }
        },
        selectProductType(event, p_type, p_type_index) {
            // this.product_type_data.forEach((el,index) => {
            //     el.selected = false
            // }
            // this.product_type_data[p_type_index].selected = !this.product_type_data[p_type_index].selected;
            let selected_product_type = []
            this.product_type_data.forEach((el,index) => {
                if(el.selected) {
                    selected_product_type.push(el)
                }
            })
            this.selected_product_type = Array.from(new Set(selected_product_type.map(a => a._id)))
                .map(id => {
                return selected_product_type.find(a => a._id === id)
                })
            this.$forceUpdate();
        },
        applyFilter() {
            this.hide_filters = false
            this.$emit('hideFilters')
            this.$emit('UpdateByFilter', this.selected_product_type)
        },
    },
    computed:{
        totalFilters(){
            return this.selected_product_type.length
        },
        loggedInUser() {
            return this.$store.state.loggedInUser
        },
    },
    mounted(){
        setTimeout(() => {
            this.sub_category_id = this.new_selected_sub_category_id
            this.category_id = this.new_selected_category_id
            this.loadProductTypeFilterData();
        }, 1000);
        
        EventBus.$on('product_type_filter',(params)=>{
            console.log(params)
            this.category_id = params.category_id
            this.sub_category_id = params.sub_category_id
            this.loadProductTypeFilterData();
        })
        EventBus.$on('removeProductTypeFilter',(params)=>{
            console.log(params)
            this.product_type_data.forEach((el1,index1) => {
                if(params._id == el1._id) {
                    el1.selected = false;
                }
            })
        })
    }
}
</script>
<style scoped>
.category-multiple-addon .custom__tag {
    background: #00448b !important;
    color: #fff;
    border-radius: 18px !important;
}
.category-multiple-addon .multiselect__strong {
    line-height: 26px!important;
}
.product-type .product-type-option {
    
}
.product-type-option-image {
    padding: 7.4px 7.6px 5.6px 6.8px;
    background-color: transparent;
    border-radius: 5px;
    width: 50px;
    height: 50px;
}
.product-type-option-selected .product-type-option-image {
    background-color: #00448b;
}
.form-check-label.product-type-option {
    padding-left: 0px !important;
}
</style>